// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FundNotice_container__l25iN {\n\n    border-top: 1px dashed #8e8e8e;\n\n    padding-top: 2rem;\n\n    margin-top: 2rem;\n\n    display: flex\n;\n    flex-direction: row;\n\n    align-items: center;\n\n    gap: 2rem;\n\n    flex-wrap: wrap;\n    \n    justify-content: center;\n\n}\n\n.FundNotice_notice__f-U72 {\n\n    flex: 1 1;\n\n    color: #8e8e8e;\n\n}\n\n.FundNotice_notice__f-U72 > *:not( :last-child ) {\n\n    margin-bottom: 0.5rem;\n\n}\n\n.FundNotice_image__tRew3 {\n\n    max-width: 256px;\n\n}\n\n.FundNotice_title__RVB4p {\n\n    color: white;\n\n    font-weight: 500;\n\n}\n\n.FundNotice_text__TJREg {\n\n    font-size: 0.875rem;\n\n    line-height: 1.75;\n\n}", "",{"version":3,"sources":["webpack://./src/Components/Layout/Footer/FundNotice/FundNotice.module.css"],"names":[],"mappings":"AAAA;;IAEI,8BAA8B;;IAE9B,iBAAiB;;IAEjB,gBAAgB;;IAEhB;AACJ;IACI,mBAAmB;;IAEnB,mBAAmB;;IAEnB,SAAS;;IAET,eAAe;;IAEf,uBAAuB;;AAE3B;;AAEA;;IAEI,SAAO;;IAEP,cAAc;;AAElB;;AAEA;;IAEI,qBAAqB;;AAEzB;;AAEA;;IAEI,gBAAgB;;AAEpB;;AAEA;;IAEI,YAAY;;IAEZ,gBAAgB;;AAEpB;;AAEA;;IAEI,mBAAmB;;IAEnB,iBAAiB;;AAErB","sourcesContent":[".container {\n\n    border-top: 1px dashed #8e8e8e;\n\n    padding-top: 2rem;\n\n    margin-top: 2rem;\n\n    display: flex\n;\n    flex-direction: row;\n\n    align-items: center;\n\n    gap: 2rem;\n\n    flex-wrap: wrap;\n    \n    justify-content: center;\n\n}\n\n.notice {\n\n    flex: 1;\n\n    color: #8e8e8e;\n\n}\n\n.notice > *:not( :last-child ) {\n\n    margin-bottom: 0.5rem;\n\n}\n\n.image {\n\n    max-width: 256px;\n\n}\n\n.title {\n\n    color: white;\n\n    font-weight: 500;\n\n}\n\n.text {\n\n    font-size: 0.875rem;\n\n    line-height: 1.75;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "FundNotice_container__l25iN",
	"notice": "FundNotice_notice__f-U72",
	"image": "FundNotice_image__tRew3",
	"title": "FundNotice_title__RVB4p",
	"text": "FundNotice_text__TJREg"
};
export default ___CSS_LOADER_EXPORT___;
